import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function Hero() {
  return (
    <div className="hero-container">
      <div
        className="left-side-hero"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <h4 className="animate__animated animate__heartBeat h4">
          Supercharge Your <br /> Exam Preparation
        </h4>

        <p>
          Get ready to excel in your O'level, International, <br />
          and Professional Exams with the power of ExcelMind's <br />
          innovative Past Question Simulator.
        </p>

        <div className="download-botton">
          <img src="./images/playstore.svg" className="playstore" />
          <img src="./images/appstore.svg" className="appstore" />
        </div>

        <div className="account">
          <Link to="/login">
            <button className="sign-in">Sign In</button>
          </Link>
          <Link to="/SignUp">
            <button>Create Account</button>
          </Link>
        </div>
      </div>

      <div
        className="left-side-hero"
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <img className="hero-img" src="./images/header-pic.png" />
      </div>
    </div>
  );
}
