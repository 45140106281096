import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingHome from "./pages/LandingPage/Home";
import LandingPage from "./pages/LandingPage/Landing";
import About from "./Components/About";
import Solution from "./Components/Solution";
import Pricing from "./Components/Pricing";
import Blogs from "./Components/Blogs";
import Policy from "./pages/LandingPage/Policy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}>
          <Route path="" element={<LandingHome />} />
          <Route path="privacy-policy" element={<Policy />} />
        </Route>
        <Route path="/About" element={<About />} />
        <Route path="/Solution" element={<Solution />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Blogs " element={<Blogs />} />
        <Route
          path="dashboard"
          element={<Navigate replace to="/dashboard/home" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
