const questions = [
  {
    id: 1,
    title: "What is the site all about?",
    info: "Excelmind is an educational platform that provides past questions from some of the most popular exams in Nigeria and internationally. ",
  },
  {
    id: 2,
    title: "Can I share my email and password with someone else?",
    info: "No, it is not recommended to share your personal login information with others. Each account is intended for individual use only.",
  },

  {
    id: 4,
    title: "How long can I have access to  excelmind?",
    info: "The access period may vary depending on the subscription plan you choose. Please refer to the subscription options on the Excelmind website for more information.",
  },
  {
    id: 5,
    title: "How many courses does excelmind offer for each exam?",
    info: "The number of courses offered for each exam may vary. Please refer to the exam list on the Excelmind website for more information.",
  },
  {
    id: 6,
    title: "What are the types of exam I can access on Excelmind?",
    info: "Excelmind offers past questions from a variety of popular exams in Nigeria and internationally, including university entrance exams, professional certification exams, and more. Please refer to the exam list on the Excelmind website for a full list of exams available.",
  },
  {
    id: 7,
    title: " Can Excelmind get me prepared for a professional exam?",
    info: "Excelmind provides past questions from a variety of professional certification exams, which can be useful as a study resource to help you prepare for the exam. However, it is important to note that it is not a substitute for other study materials and professional guidance.",
  },
];
export default questions;
