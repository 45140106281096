import React from "react";
import "../../css/footer/footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="logo-info">
        <img className="logo" src="./images/inverted-logo.svg" />
        <p>
          At Excel Mind, we believe everyone deserves the best chance possible
          to excel academically. We are driven to provide innovations through
          technology that makes learning convenient,
          accessible and pocket friendly.
        </p>

        <div className="social-media">
          <img src="./images/Facebook.svg" />
          <img src="./images/Twitter.svg" />
          <img src="./images/Instagram.svg" />
          <img src="./images/LinkedIn.svg" />
        </div>
      </div>
      <div className="quick-links">
        <h1>Quick Links</h1>
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Exams</li>
          <li>Blogs</li>
          <li>Pricing</li>
        </ul>
      </div>
      <div className="features">
        <h1>Features</h1>
        <ul>
          <li>WAEC</li>
          <li>JAMB</li>
          <li>NECO</li>
          <li>Cambridge</li>
          <li>IELTS</li>
          <li>etc...</li>
        </ul>
      </div>
      <div className="support">
        <h1>Support</h1>
        <ul>
          <li>Support Center</li>
          <li>Feedback</li>
          <li>Terms & Condition</li>
        </ul>
      </div>
    </div>
  );
}
